import { CustomerFocus, FundingType, Prisma } from "@prisma/client"
import { isEmpty } from "ramda"
import { companySignalFiltersValidation } from "~/components/Filters/schemas/company"
import { FUNDING_TYPE } from "~/consts/signals"
import { EXCLUDE_PREFIX } from "~/consts/signals"
import { SortingParameter } from "~/utils/db/queries/types"
import { companySignalSortValidation } from "./db/sorting"
import { CompanySignalColumnMapping } from "./prisma/mappings"
import { isNullish } from "./values"

export const getRankColor = (rank: number): string => {
  switch (rank) {
    case 1:
      return "yellow.600"
    case 2:
      return "gray.500"
    case 3:
      return "orange.500"
    default:
      return "brand.500"
  }
}

const invertedOp = (op: string) => {
  switch (op) {
    case "gte":
      return "lte"
    case "gt":
      return "lt"
    case "lte":
      return "gte"
    case "lt":
      return "gt"
    default:
      return op
  }
}

/**
 * Some filters on the API don't have an operator set. This function normalises
 * the API filter so that an appropriate operator is chosen based on the field.
 *
 * Makes sure that the field uses the correct internal syntax,
 * * in for list of values
 * * not.search for does not contain
 * * search for contains
 */
export function normaliseCompanySignalApiFilters(
  whereObj: Prisma.JsonValue
): Record<string, any> {
  return Object.fromEntries(
    Object.entries(companySignalFiltersValidation.parse(whereObj)).map(
      ([field, values]) => {
        if (
          [
            "growthStage",
            "HQRegion",
            "AnnualRevenueEstimate",
            "LastFundingType",
          ].includes(field)
        ) {
          // Map the values to the correct format (company only)
          if (field === "LastFundingType") {
            const newValues = Array.isArray(values)
              ? values?.map((value) => {
                  const isExcluded = String(value).startsWith(EXCLUDE_PREFIX)
                  const valueBase = String(value).replace(EXCLUDE_PREFIX, "")

                  const mappedBaseValue =
                    FUNDING_TYPE[valueBase as FundingType] ?? valueBase

                  return isExcluded
                    ? `${EXCLUDE_PREFIX}${mappedBaseValue}`
                    : mappedBaseValue
                })
              : values

            return [field, ["in", newValues]]
          }

          return [field, ["in", values]]
        }
        if (["Description"].includes(field)) {
          if (values) {
            // @ts-ignore
            if (values[0] == "not.contains") {
              // @ts-ignore
              return ["description_search", ["not.search", values[1]]]
            } else {
              // @ts-ignore
              return ["description_search", ["search", values[1]]]
            }
          }
        }

        // Handle composed growth filters
        if (field.startsWith("composed_growth")) {
          let [period, [op, value1, value2]] = values as [
            string,
            [string, number, number | null]
          ]

          const [_, metric, type = "ratio"] = field.split(".")

          const column = `cgm.${metric}_${period}_${type}`

          const parsePercentageToRatio = (value: number) => value / 100 + 1

          const INVERTED_METRICS = ["popularity_rank"]

          if (op === "between" && !isNullish(value2)) {
            // Invert the values if the metric is inverted
            if (INVERTED_METRICS.includes(metric)) {
              ;[value1, value2] = [-value2, -value1]
            }

            // App downloads are not yet supported in the new data format.
            // ! When supported, remove this code.
            if (metric === "total_app_downloads") {
              // "Total App Downloads - Monthly Growth"
              const appDownloadsColumn = `Total App Downloads - ${
                period === "1mo"
                  ? "Monthly"
                  : `${period.replace("mo", "")}Months`
              } Growth`

              return [appDownloadsColumn, [value1, value2]]
            }

            return [
              column,
              type === "ratio"
                ? [
                    parsePercentageToRatio(value1),
                    parsePercentageToRatio(value2),
                  ]
                : [value1, value2],
            ]
          }

          // Invert the op and value if the metric is inverted
          if (INVERTED_METRICS.includes(metric)) {
            ;[op, value1] = [invertedOp(op), -value1]
          }

          // App downloads are not yet supported in the new data format.
          // ! When supported, remove this code.
          if (metric === "total_app_downloads") {
            // "Total App Downloads - Monthly Growth"
            const appDownloadsColumn = `Total App Downloads - ${
              period === "1mo" ? "Monthly" : `${period.replace("mo", "")}Months`
            } Growth`

            return [appDownloadsColumn, [op, value1]]
          }

          return [
            column,
            [op, type === "ratio" ? parsePercentageToRatio(value1) : value1],
          ]
        }

        if (
          CompanySignalColumnMapping[
            field as keyof typeof CompanySignalColumnMapping
          ]?.endsWith("_ratio")
        ) {
          const [op, value] = values as [string | number, number]
          const opOrValue = typeof op === "string" ? op : op / 100 + 1

          return [field, [opOrValue, value / 100 + 1]]
        }

        return [field, values]
      }
    )
  )
}

/**
 * Some filters on the API don't have an operator set. This function normalises
 * the API filter so that an appropriate operator is chosen based on the field.
 */
export function normaliseCompanySortParams(
  sortParam: Record<string, any> | null
): SortingParameter[] {
  if (isNullish(sortParam) || isEmpty(sortParam)) {
    return [
      { column: "Rank", direction: "asc" },
      { column: "id", direction: "asc" },
    ]
  }

  const validatedParams = companySignalSortValidation.parse(sortParam)

  // semanticScore is handled separately, not by the default logic
  return Object.entries(validatedParams)
    .filter(([column]) => column !== "semanticScore")
    .map(([column, direction]) => {
      return { column, direction }
    })
}

export function getCustomerFocusName(b2x: CustomerFocus | null) {
  switch (b2x) {
    case CustomerFocus.b2c:
      return "B2C"
    case CustomerFocus.b2b:
      return "B2B"
    case CustomerFocus.b2c_b2b:
      return "B2C (Primary) / B2B (Secondary)"
    case CustomerFocus.b2b_b2c:
      return "B2B (Primary) / B2C (Secondary)"
    default:
      return null
  }
}

export function getCustomerFocusColor(b2x: CustomerFocus | null) {
  switch (b2x) {
    case CustomerFocus.b2c:
      return "orange"
    case CustomerFocus.b2b:
      return "brand"
    case CustomerFocus.b2c_b2b:
      return "orange"
    case CustomerFocus.b2b_b2c:
      return "brand"
    default:
      return "gray"
  }
}
