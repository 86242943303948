import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  NumberInput,
  NumberInputField,
  Spinner,
  Tag,
} from "@chakra-ui/react"
import * as R from "ramda"
import { ReactNode, useState } from "react"
import invariant from "~/utils/invariant"

import {
  CustomerFocus,
  GrowthStage,
  InvestorHighlight,
  InvestorType,
  SpecterProducts,
  StrategicSourceType,
} from "@prisma/client"
import { format } from "date-fns"
import { FormikProps } from "formik"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import {
  z,
  ZodAny,
  ZodArray,
  ZodBoolean,
  ZodDefault,
  ZodEffects,
  ZodEnum,
  ZodFirstPartySchemaTypes,
  ZodNativeEnum,
  ZodNullable,
  ZodNumber,
  ZodObject,
  ZodOptional,
  ZodString,
  ZodTuple,
  ZodUnion,
} from "zod"
import {
  ComposedMenuSelect,
  CreatableReactSelect,
  ReactSelect,
} from "~/components"
import { DatePicker } from "~/components/DatePicker"
import {
  ASYNC_PROPERTIES_WITH_NAMING,
  AutoCompleteSelect,
} from "~/components/ReactSelect/autocomplete"
import {
  ACQUISITION_TYPE,
  ANNUAL_REVENUE_RANGES,
  CompanyHighlight,
  COMPANY_HIGHLIGHTS_GROUPS,
  COMPANY_HIGHLIGHTS_PROPS,
  COMPANY_SIZE_OPTIONS,
  COMPANY_SUB_INDUSTRY,
  EXCLUDE_PREFIX,
  FUNDING_TYPE,
  GROWTH_STAGE_LABEL,
  INDUSTRY,
  INVESTOR_HIGHLIGHTS_PROPS,
  PeopleHighlight,
  PEOPLE_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import {
  allTalentSignalDepartment,
  allTalentSignalSeniority,
  allTalentSignalStatus,
  allTalentSignalType,
} from "~/consts/talentSignals"
import {
  AsyncInputProperty,
  ASYNC_INPUT_FIELDS_TO_PROPERTY,
} from "~/routes/__protected/api/input-options.$product.$property"
import { cacheKeys } from "~/utils/cacheKeys"
import { getCustomerFocusName } from "~/utils/companySignals"
import { GetOptionsReturn } from "~/utils/db/options"
import { isDateValid } from "~/utils/isDateValid"
import { isOneOf } from "~/utils/isOneOf"
import { queryClient } from "~/utils/queryClient"
import {
  ALL_OPERATORS,
  AND_OR_OPERATORS,
  DATE_RANGE_OPTIONS,
  equalsOperatorsFirst,
  getSignalFieldTitle,
  GROWTH_PERIODS,
  GROWTH_PERIODS_SCHEMA,
  GROWTH_PERIODS_SHORT_LABELS,
  NUMERIC_OPERATORS,
  NUMERIC_OPERATORS_REVAMPED,
  OperatingStatus,
  OperatingStatusEnum,
  OPERATING_STATUS_LABEL,
  TIME_FRAME_OPTIONS,
} from "~/utils/signal"
import { isNullish } from "~/utils/values"
import { ListFilterSelect } from "../ListFilterSelect"
import { isKeyOf } from "~/utils/isKeyOf"

export const NEW_FEATURED_FILTERS: string[] = ["Founders", "FounderHighlights"]
export const NEW_FEATURED_FILTER_SECTIONS: string[] = []
export const NEW_FEATURED_FILTER_SUB_SECTIONS: string[] = []

// This defines the fields that will have the first array element as a toggle button (OR/AND most of the time)
export const SEARCH_AS_YOU_TYPE_FIELDS: (string | [string, SpecterProducts])[] =
  [
    "Industry",
    "SubIndustry",
    "b2x",
    "certifications_mapped",
    "ActiveTechnologies",
    "Awards",
    "Highlights",
    "NewHighlights",
    "SourceType",
    "SignalSource",
    "Investors",
    "CompanyInvestors",
    "types",
    "stages",
    "targetIndustries",
    "CompanyIndustries",
    "Location",
    "HQLocation",
    ["Languages", "people"],
    "Skills",
    "FieldOfStudy",
    ["Education", "people"],
    "Companies",
    "PeopleHighlights",
    "CompanyHQLocation",
    "EducationLocation",
    "JobTitles",
    "PastJobTitles",
    "Department",
    "clients_mapped",
    "Founders",
    "FounderHighlights",
    "Person",
  ]

const isHideTabs = (schema: any): boolean => {
  if (typeof schema === "string") {
    return Object.values(NUMERIC_OPERATORS_REVAMPED).includes(schema as any)
  }

  if (schema instanceof ZodEffects) {
    return isHideTabs(schema.sourceType())
  }

  if (schema instanceof ZodNativeEnum) {
    return (
      typeof schema._def.values === "object" &&
      Object.values(NUMERIC_OPERATORS_REVAMPED).every((v) => {
        return Object.values(schema._def.values).includes(v)
      })
    )
  }

  if (schema instanceof ZodEnum) {
    return isHideTabs(schema.options[0])
  }

  if (schema instanceof ZodTuple) {
    return isHideTabs(schema.items[0])
  }

  if (schema instanceof ZodUnion) {
    return isHideTabs(schema.options[0])
  }

  if (schema instanceof ZodDefault) {
    return isHideTabs(schema._def.innerType)
  }

  return false
}

export const getTabLabel = (field: string, index: number, option: any) => {
  if (field.startsWith("composed_growth") && option instanceof ZodTuple) {
    return GROWTH_PERIODS_SHORT_LABELS[GROWTH_PERIODS[index]]
  }

  if (
    SEARCH_AS_YOU_TYPE_FIELDS.some((v) =>
      Array.isArray(v) ? v[0] === field : v === field
    )
  ) {
    return AND_OR_OPERATORS[index]
  }

  const isDateField = isOneOf(field, DATE_FIELDS)

  if (isDateField) {
    return ["Default", "Custom", "Rolling Range"][index]
  }

  if (field === "HotSignals" || field === "HotCompanies") {
    return index === 0 ? "Time Frame" : "Signal Count"
  }

  return index === 1 ? "Between" : index === 2 ? "In the last" : "Default"
}

interface FilterAccordionProps {
  field: string
  filterApplied: boolean
  isOpen: boolean
  children?: ReactNode
}

const FilterAccordion = ({
  field,
  filterApplied,
  isOpen,
  children,
}: FilterAccordionProps): JSX.Element => {
  const title = getSignalFieldTitle(field)
  const isNew = NEW_FEATURED_FILTERS.includes(field)

  return (
    <Accordion allowToggle defaultIndex={isOpen ? [0] : undefined}>
      <AccordionItem border={0}>
        <AccordionButton
          fontSize="sm"
          textAlign="left"
          _focus={{
            boxShadow: "none",
          }}
          _focusVisible={{
            outline: "-webkit-focus-ring-color auto 1px",
            outlineOffset: "1px",
          }}
        >
          {title}
          {isNew && (
            <Tag colorScheme="brand" ml={2}>
              New {field.endsWith("Location") && "Regions"}
            </Tag>
          )}
          {filterApplied && (
            <Box bgColor="red.400" ml={2} w={1} h={1} rounded="full" />
          )}
          <AccordionIcon ml="auto" />
        </AccordionButton>
        <AccordionPanel pb={2}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const getDatePlaceholder = (index?: number, tab?: number): string => {
  if (isNullish(tab) || tab === 0 || isNullish(index)) {
    return "Date"
  }

  return ["Start date", "End date"][index]
}

const getNumberPlaceholder = ({
  field,
  index,
  tab,
}: {
  field?: string
  index?: number
  tab?: number
}): string => {
  if (
    isNullish(tab) ||
    tab === 0 ||
    isNullish(index) ||
    isNullish(field) ||
    ["HotSignals", "HotCompanies"].includes(field) ||
    field.startsWith("composed_growth")
  ) {
    return "Number"
  }

  // Tab 2 is the date rolling range tab
  if (tab === 2) {
    return "Number"
  }

  return ["Min", "Max"][index]
}

const getSelectPlaceholder = (
  {
    field,
    index,
    product,
  }: {
    field?: string
    index?: number
    product?: SpecterProducts
  },
  fallback?: string
): string | undefined => {
  if (field === "growthStage") {
    return "E.g: Bootstrapped, Growth Stage, etc."
  }

  if (field === "Highlights" && product === SpecterProducts.company) {
    return "E.g: Headcount Surge, Top Tier Investors, etc."
  }

  if (
    field === "Highlights" &&
    (product === SpecterProducts.people || product === SpecterProducts.talent)
  ) {
    return "E.g: Prior Exit, Serial Founder, etc."
  }

  if (field === "OperatingStatus") {
    return "E.g: Active, Closed, Acquired, IPO"
  }

  if (field === "b2x") {
    return "E.g: B2B, B2C"
  }

  if (field === "Industry") {
    return "E.g: Finance, Health, DeepTech, etc."
  }

  if (field === "SubIndustry") {
    return "E.g: Machine Learning, Cloud Management, SEO, etc."
  }

  if (field === "tcp") {
    return "E.g: developer, lawyer, etc."
  }

  if (field === "Department") {
    return "Engineering, Operations, Finance, etc."
  }

  if (field === "CompanyIndustries") {
    return "E.g: Finance, Health, DeepTech, etc."
  }

  if (field === "CompanyGrowthStage") {
    return "E.g: Bootstrapped, Growth Stage, etc."
  }

  if (field === "EducationLevel") {
    return "E.g: Bachelor, Master, etc."
  }

  if (!isNullish(field) && ["CurrentTenure", "AverageTenure"].includes(field)) {
    return ["Minimum", "Maximum"][index ?? 0]
  }

  return fallback
}

const OPERATOR_LABELS = {
  gte: "Greater or equal",
  gt: "Greater",
  lte: "Less or equal",
  lt: "Less",
  equals: "Equals",
  between: "Between",
  contains: "Contains",
  after: "After",
  before: "Before",
  "not.contains": "Not contains",
  "not.equals": "Not equals",
}

export const getOptionLabel = (
  option: string,
  field: string,
  product: SpecterProducts
): ReactNode => {
  // Remove the exclude prefix from labels
  option = option.replace(EXCLUDE_PREFIX, "")

  if (field === "semanticSearch") {
    return `"${option}"`
  }

  if (isKeyOf(option, OPERATOR_LABELS)) {
    return OPERATOR_LABELS[option]
  }

  if (isKeyOf(option, ACQUISITION_TYPE)) {
    return ACQUISITION_TYPE[option]
  }

  if (
    isOneOf(option, allTalentSignalStatus) ||
    isOneOf(option, allTalentSignalType) ||
    isOneOf(option, allTalentSignalSeniority) ||
    isOneOf(option, allTalentSignalDepartment) ||
    isOneOf(option, Object.values(ANNUAL_REVENUE_RANGES))
  ) {
    return option
  }

  if (
    ["growthStage", "CompanyGrowthStage"].includes(field) ||
    (!field && isKeyOf(option, GrowthStage))
  ) {
    return GROWTH_STAGE_LABEL[option as GrowthStage]
  }

  if (
    ["operatingStatus", "OperatingStatus"].includes(field) ||
    (!field && isKeyOf(option, OperatingStatusEnum))
  ) {
    return OPERATING_STATUS_LABEL[option as OperatingStatus]
  }

  if (field === "b2x" && isOneOf(option, Object.values(CustomerFocus))) {
    return getCustomerFocusName(option)
  }

  if (
    ["Highlights", "NewHighlights", "FounderHighlights"].includes(field) ||
    (!field && isKeyOf(option, COMPANY_HIGHLIGHTS_PROPS))
  ) {
    return (
      COMPANY_HIGHLIGHTS_PROPS[option as CompanyHighlight]?.label ??
      INVESTOR_HIGHLIGHTS_PROPS[option as InvestorHighlight]?.label ??
      PEOPLE_HIGHLIGHTS_PROPS[option as PeopleHighlight]?.label ??
      option
    )
  }

  if (
    isOneOf(option, Object.values(DATE_RANGE_OPTIONS)) ||
    isOneOf(option, Object.keys(StrategicSourceType)) ||
    isOneOf(option, Object.values(InvestorType)) ||
    isOneOf(option, TIME_FRAME_OPTIONS)
  ) {
    return titleCase(sentenceCase(option))
  }

  const asyncField = ASYNC_INPUT_FIELDS_TO_PROPERTY[field as string]

  if (
    isOneOf(asyncField, ASYNC_PROPERTIES_WITH_NAMING) &&
    // TODO: This is a temporary fix to avoid showing the autocomplete for the "Languages" field in Talent
    // TODO: Same for "Education" in Talent
    !(product === SpecterProducts.talent && field === "Languages") &&
    !(product === SpecterProducts.talent && field === "Education")
  ) {
    const optionNames = queryClient.getQueryData<
      Record<string, GetOptionsReturn[number]>
    >(cacheKeys.inputOptions(asyncField))

    return optionNames?.[option]?.label ?? <Spinner size="xs" />
  }

  if (
    field === "CompanySize" &&
    isOneOf(option, Object.keys(COMPANY_SIZE_OPTIONS))
  ) {
    return COMPANY_SIZE_OPTIONS[option]
  }

  if (isOneOf(option, Object.keys(FUNDING_TYPE))) {
    return FUNDING_TYPE[option]
  }

  // Don't apply titleCase and sentenceCase to free text or numbers
  // that the user provides as it can remove symbols like `,` and operators
  return option
}

interface Props<Schema, Value> {
  schema: Schema
  value: Value
  onChange: (value: Value) => void
  onBlur: FormikProps<Value>["handleBlur"]
  product: SpecterProducts
  field?: string
  isArray?: true
  index?: number
  tab?: number
  type: "quickFilters" | "advancedFilters"
  defaultOperator?: any
}

export const DATE_FIELDS = [
  "acquiredOn",
  "announcedOn",
  "SignalDate",
  "LastFundingDate",
  "LastActivityDate",
  "NewHires",
  "RecentLeavers",
  "RecentPromotions",
]

export function FieldsFromSchema<
  Schema extends ZodFirstPartySchemaTypes,
  Value extends z.infer<Schema>
>({
  schema,
  value,
  onChange,
  onBlur,
  product,
  field,
  isArray,
  index,
  tab,
  type,
  defaultOperator = null,
}: Props<Schema, Value>) {
  // Traits
  const isDateField = isOneOf(field, DATE_FIELDS)

  // Specific overrides

  if (field === "listId") {
    return (
      <ListFilterSelect
        product={product}
        value={value}
        onChange={(val) => {
          onChange(val as Value)
        }}
      />
    )
  }

  if (
    Object.keys(ASYNC_INPUT_FIELDS_TO_PROPERTY).includes(field as string) &&
    schema instanceof ZodArray &&
    // TODO: This is a temporary fix to avoid showing the autocomplete for the "Investors" field in StratIntel
    // TODO: Same for "Languages" in Talent
    // TODO: Same for "Education" in Talent
    !(product === SpecterProducts.stratintel && field === "Investors") &&
    !(product === SpecterProducts.talent && field === "Languages") &&
    !(product === SpecterProducts.talent && field === "Education")
  ) {
    return (
      <AutoCompleteSelect
        placeholder={getSelectPlaceholder({ field, index })}
        field={field as AsyncInputProperty}
        product={product}
        onChange={(val) => onChange(val as Value)}
        onBlur={onBlur}
        value={value}
      />
    )
  }

  if (
    ["company", "people", "talent"].includes(product) &&
    isOneOf(field, ["Highlights", "NewHighlights", "FounderHighlights"]) &&
    schema instanceof ZodArray &&
    schema.element instanceof ZodEnum
  ) {
    const options = schema.element.options.filter(
      (opt: string) => !opt.startsWith(EXCLUDE_PREFIX)
    ) as string[]

    const highlightsOfProduct = {
      Highlights:
        product === SpecterProducts.company
          ? SpecterProducts.company
          : SpecterProducts.people,
      NewHighlights: product,
      FounderHighlights: SpecterProducts.people,
    }[field]

    return (
      <ReactSelect
        isMulti
        allowExclude
        inputId={field}
        instanceId={field}
        isClearable={true}
        placeholder={getSelectPlaceholder({ field, index, product })}
        options={options.map((option) => {
          let group, label, colorScheme

          if (highlightsOfProduct === SpecterProducts.company) {
            ;({ group, label } =
              COMPANY_HIGHLIGHTS_PROPS[option as CompanyHighlight])
            ;({ colorScheme } = COMPANY_HIGHLIGHTS_GROUPS[group])
          }

          if (highlightsOfProduct === SpecterProducts.people) {
            ;({ label, colorScheme } =
              PEOPLE_HIGHLIGHTS_PROPS[option as PeopleHighlight])
          }

          return {
            value: option,
            label: (
              <>
                <Box
                  boxSize={2}
                  bgColor={`${colorScheme ?? "brand"}.400`}
                  borderRadius="full"
                  mr={2}
                />
                {label}
              </>
            ),
          }
        })}
        value={value?.map((option: any) => ({
          label: getOptionLabel(option, field!, product),
          value: option,
        }))}
        onChange={(selected: any) => {
          onChange(selected?.map((option: any) => option.value))
        }}
        onBlur={onBlur}
      />
    )
  }

  if (
    field !== undefined &&
    SEARCH_AS_YOU_TYPE_FIELDS.some((v) =>
      Array.isArray(v) ? v[0] === field && v[1] === product : v === field
    ) &&
    !(product === SpecterProducts.stratintel && field === "Investors")
  ) {
    if (schema instanceof ZodTuple) {
      return (
        <FieldsFromSchema
          product={product}
          key={index}
          field={field}
          schema={schema.items[1]}
          value={value?.[1]}
          tab={tab}
          type={type}
          onChange={(val) => {
            if (val === null || val?.length === 0) {
              onChange(null as Value)
              return
            }

            const operator = AND_OR_OPERATORS[tab ?? 0]

            onChange([operator, val] as Value)
          }}
          onBlur={onBlur}
          defaultOperator={defaultOperator}
        />
      )
    }

    if (schema instanceof ZodEnum && field == "SubIndustry") {
      return (
        <ComposedMenuSelect
          field={field}
          placeholder={getSelectPlaceholder({ field, index })}
          menuOptions={INDUSTRY}
          subMenuOptions={COMPANY_SUB_INDUSTRY}
          value={value ?? []}
          onChange={(selected: any) => onChange(selected as Value)}
          onBlur={onBlur}
          placement={{
            menu: type === "advancedFilters" ? "auto" : "left-end",
            subMenu: "right-start",
          }}
          allowExclude
        />
      )
    }
  }

  if (schema instanceof ZodObject) {
    return (
      <>
        {Object.entries(schema.shape).map(([innerField, innerSchema]) => {
          invariant(innerSchema instanceof z.ZodType)

          return (
            <FilterAccordion
              key={innerField}
              field={innerField}
              filterApplied={R.has(innerField, value)}
              isOpen={R.has(innerField, value)}
            >
              <FieldsFromSchema
                product={product}
                schema={innerSchema}
                field={innerField}
                value={value[innerField]}
                onChange={(val) => {
                  if (val === null) {
                    onChange(R.omit([innerField], value) as Value)
                  } else {
                    onChange({
                      ...value,
                      [innerField]: val,
                    })
                  }
                }}
                onBlur={onBlur}
                type={type}
                defaultOperator={defaultOperator}
              />
            </FilterAccordion>
          )
        })}
      </>
    )
  }

  if (schema instanceof ZodOptional && field !== undefined) {
    return (
      <FieldsFromSchema
        product={product}
        schema={schema.unwrap()}
        field={field}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        index={index}
        tab={tab}
        type={type}
      />
    )
  }

  if (schema instanceof ZodNullable && field !== undefined) {
    return (
      <FieldsFromSchema
        product={product}
        schema={schema.unwrap()}
        field={field}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        defaultOperator={defaultOperator}
        index={index}
        tab={tab}
        type={type}
      />
    )
  }

  if (schema instanceof ZodDefault && field !== undefined) {
    return (
      <FieldsFromSchema
        product={product}
        schema={schema._def.innerType}
        field={field}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        defaultOperator={schema._def.defaultValue()}
        index={index}
        tab={tab}
        type={type}
      />
    )
  }

  if (schema instanceof ZodEffects && field !== undefined) {
    return (
      <FieldsFromSchema
        product={product}
        schema={schema.sourceType()}
        field={field}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        index={index}
        tab={tab}
        type={type}
      />
    )
  }

  if (
    (schema instanceof ZodNativeEnum || schema instanceof ZodEnum) &&
    field !== undefined
  ) {
    let rawOptions: string[] = Object.values(schema._def.values)

    // Set the default option from the default value
    let defaultOption = defaultOperator
      ? {
          value: defaultOperator,
          label: getOptionLabel(defaultOperator, field, product),
        }
      : null

    // Overrides
    if (field === "NewCompanyWebsite" || field === "PastCompanyWebsite") {
      rawOptions = equalsOperatorsFirst(rawOptions)
      defaultOption = {
        value: rawOptions[0],
        label: getOptionLabel(rawOptions[0], field, product),
      }
    }

    const options = rawOptions
      .filter(
        (option) =>
          !option.startsWith(EXCLUDE_PREFIX) &&
          // Don't show in select options for excluded values
          (!Array.isArray(value) ||
            // When "<exclude>:<value>" is selected, don't show "<value>" in the options
            !value?.some(
              (v: string) => v.replace(EXCLUDE_PREFIX, "") === option
            ))
      )
      .map((option) => ({
        value: option,
        label: getOptionLabel(option, field, product),
      }))

    return (
      <Box flex={1}>
        {isArray ? (
          <ReactSelect
            allowExclude={rawOptions.some((v) => v.startsWith(EXCLUDE_PREFIX))}
            isMulti
            inputId={field}
            instanceId={field}
            isClearable={!defaultOption}
            placeholder={getSelectPlaceholder({ field, index }, "All")}
            options={options}
            defaultValue={defaultOption}
            value={
              value?.map((option: any) => ({
                label: getOptionLabel(option, field, product),
                value: option,
              })) ?? [defaultOption]
            }
            onChange={(selected: any) => {
              onChange(selected?.map((option: any) => option.value))
            }}
            onBlur={onBlur}
          />
        ) : (
          <ReactSelect<{ label: unknown; value: any }>
            inputId={field}
            instanceId={field}
            isClearable={!defaultOption}
            defaultValue={defaultOption}
            value={
              isOneOf(value, rawOptions)
                ? {
                    label: getOptionLabel(value, field, product),
                    value: value,
                  }
                : defaultOption
            }
            onChange={(option) => {
              if (option?.value) {
                onChange(option.value)
              } else {
                onChange(defaultOption as Value)
              }
            }}
            options={options}
            onBlur={onBlur}
            placeholder={getSelectPlaceholder({ field, index })}
          />
        )}
      </Box>
    )
  }

  // * Growth composed filter - Pass down the inner schema
  if (
    schema instanceof ZodTuple &&
    field?.startsWith("composed_growth") &&
    schema.items[0] === GROWTH_PERIODS_SCHEMA
  ) {
    return (
      <FieldsFromSchema
        product={product}
        schema={schema.items[1]}
        field={field}
        value={value?.[1]}
        onChange={(val) => {
          if (val === null || val?.length === 0) {
            onChange(null as Value)
            return
          }

          const period = GROWTH_PERIODS[tab ?? 0]

          onChange([period, val] as Value)
        }}
        onBlur={onBlur}
        defaultOperator={defaultOperator}
        index={index}
        tab={tab}
        type={type}
      />
    )
  }

  if (schema instanceof ZodTuple && field !== undefined) {
    const operatorDefaultValue =
      (schema?.items[0]?._def?.defaultValue &&
        schema.items[0]._def.defaultValue()) ||
      defaultOperator

    let items = schema.items
    const hideTabs = isHideTabs(schema)

    if (
      hideTabs &&
      (!value || value[0] !== NUMERIC_OPERATORS_REVAMPED.BETWEEN)
    ) {
      items = schema.items.slice(0, -1)
    }

    return (
      <Flex gap={1}>
        {items.map((innerSchema: any, index: number) => {
          // * If it's a date field, don't pass the index to the date input (for setting the right placeholder)
          // * Same for number inputs

          return (
            <FieldsFromSchema
              product={product}
              key={index}
              field={field}
              schema={innerSchema}
              value={value?.[index]}
              index={index}
              tab={tab}
              type={type}
              onChange={(val) => {
                const update = Array.isArray(value)
                  ? [...value]
                  : new Array(schema.items.length).fill(null)

                update[index] = val

                if (
                  update.every(
                    (v) => isNullish(v) || ALL_OPERATORS.includes(v)
                  ) &&
                  val === value?.[index]
                ) {
                  onChange(null as Value)
                  return
                }

                if (isNullish(update[0])) update[0] = operatorDefaultValue

                onChange(update as Value)
              }}
              onBlur={onBlur}
              defaultOperator={defaultOperator}
            />
          )
        })}
      </Flex>
    )
  }

  if (schema instanceof ZodUnion && field !== undefined) {
    let validTab = schema.options.findIndex(
      (innerSchema: any) => innerSchema.safeParse(value).success
    )
    // * Set the tab according to AND/OR operator
    if (Array.isArray(value) && AND_OR_OPERATORS.includes(value[0])) {
      validTab = AND_OR_OPERATORS.indexOf(value[0])
    }

    let hideTabs = isHideTabs(schema)

    if (hideTabs) {
      return (
        <FieldsFromSchema
          product={product}
          schema={validTab > -1 ? schema.options[validTab] : schema.options[0]}
          field={field}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          tab={validTab > -1 ? validTab : 0}
          type={type}
        />
      )
    }

    return (
      <ToggleButtonTabs
        product={product}
        field={field}
        schema={schema}
        value={value}
        defaultIndex={validTab > -1 ? validTab : 0}
        defaultOperator={defaultOperator}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
      />
    )
  }

  if (schema instanceof ZodArray && field !== undefined) {
    return (
      <FieldsFromSchema
        product={product}
        schema={schema.element}
        value={value}
        onChange={(v) =>
          onChange((Array.isArray(v) && v.length > 0 ? v : null) as Value)
        }
        onBlur={onBlur}
        field={field}
        tab={tab}
        type={type}
        defaultOperator={defaultOperator}
        isArray
      />
    )
  }

  if (
    (schema instanceof ZodString || schema instanceof ZodAny) && // ZodAny is the custom date schema, if there's a better way to represent this please do
    isDateField
  ) {
    return (
      <DatePicker
        inputId={field}
        placeholder={getDatePlaceholder(index, tab)}
        value={isDateValid(value) ? format(new Date(value), "yyyy-MM-dd") : ""}
        onChange={(value) => onChange(value?.toDateString() as Value)}
        onBlur={onBlur}
      />
    )
  }

  if (schema instanceof ZodString) {
    return (
      <Box flex={1} maxH={"200px"} overflowY={"auto"}>
        {isArray ? (
          <CreatableReactSelect
            inputId={field}
            instanceId={field}
            placeholder={getSelectPlaceholder({ field, index }, "All")}
            isMulti
            options={[]}
            value={
              value?.map((option: string | number) => ({
                label: option,
                value: option,
              })) ?? []
            }
            onChange={(selected) => {
              const selectedValues = selected?.map((option: any) =>
                option.value.trim()
              )
              onChange(
                (selectedValues?.length > 0 ? selectedValues : null) as Value
              )
            }}
            onBlur={onBlur}
          />
        ) : (
          <Input
            id={field}
            name={field}
            value={value ?? ""}
            placeholder={getSelectPlaceholder({ field, index })}
            onChange={(e) => {
              onChange(e.currentTarget.value as any)
            }}
            onBlur={onBlur}
          />
        )}
      </Box>
    )
  }

  if (schema instanceof ZodNumber) {
    const isNonNegative = schema._def.checks?.some(
      (check) => check.kind === "min" && check.value >= 0
    )

    const isDateField =
      field?.toLowerCase().includes("date") ||
      field?.toLowerCase().includes("founded")

    return (
      <Box flex={1}>
        {isArray ? (
          <CreatableReactSelect
            inputId={field}
            instanceId={field}
            isMulti
            options={[]}
            placeholder={getSelectPlaceholder({ field, index }, "All")}
            value={
              value?.map((option: any) => ({
                label: option,
                value: option,
              })) ?? []
            }
            onChange={(selected: any) => {
              onChange(selected?.map((option: any) => option.value))
            }}
            onBlur={onBlur}
          />
        ) : (
          <NumberInput
            id={field}
            name={`${field}${!isNullish(index) ? `.${index}` : ""}`}
            pattern={
              isDateField && tab !== 2 ? "\\d{4}" : "-?\\d{1,3}(,\\d{3})*"
            }
            placeholder={getNumberPlaceholder({ field, index, tab })}
            value={
              isNullish(value)
                ? ""
                : typeof value === "number" && !isDateField
                ? value.toLocaleString("en-US")
                : value
            }
            onChange={(strValue, value: Value) => {
              if (!isNonNegative && strValue === "-") {
                onChange(strValue as Value)
                return
              }

              onChange(
                isNullish(value) ||
                  isNaN(value) ||
                  value === Number.MIN_SAFE_INTEGER // For some reason, the value is changed to MIN_SAFE_INTEGER when the input is blurred at "-". This is a workaround.
                  ? (null as Value)
                  : value
              )
            }}
            onBlur={onBlur}
          >
            <NumberInputField
              placeholder={getNumberPlaceholder({ field, index, tab })}
            />
          </NumberInput>
        )}
      </Box>
    )
  }

  if (schema instanceof ZodBoolean) {
    if (field === "LastFundingDate") {
      return (
        <Box order={-1}>
          <ReactSelect
            placeholder={getSelectPlaceholder({ field, index })}
            defaultValue={
              value === undefined
                ? undefined
                : {
                    value: value ? "true" : "false",
                    label: value ? "In the last" : "Before the last",
                  }
            }
            onChange={(option) => onChange((option?.value === "true") as Value)}
            options={[
              { value: "true", label: "In the last" },
              { value: "false", label: "Before the last" },
            ]}
          />
        </Box>
      )
    }

    return (
      <Flex gap={1}>
        <Button
          size="xs"
          variant={value === false ? "solid" : "outline"}
          colorScheme={value === false ? "brand" : "gray"}
          onClick={() => onChange(false as Value)}
        >
          No
        </Button>
        <Button
          size="xs"
          variant={value === true ? "solid" : "outline"}
          colorScheme={value === true ? "brand" : "gray"}
          onClick={() => onChange(true as Value)}
        >
          Yes
        </Button>
      </Flex>
    )
  }

  return null
}

type ToggleButtonTabsProps<Schema, Value> = {
  product: SpecterProducts
  field: string
  schema: Schema
  value: Value
  defaultIndex?: number
  defaultOperator?: any
  type: "quickFilters" | "advancedFilters"
  onChange: (value: Value) => void
  onBlur: FormikProps<Value>["handleBlur"]
}

const ToggleButtonTabs = <
  Schema extends ZodUnion<any>,
  Value extends z.infer<Schema>
>({
  product,
  field,
  schema,
  value,
  defaultIndex = 0,
  defaultOperator,
  type,
  onChange,
  onBlur,
}: ToggleButtonTabsProps<Schema, Value>) => {
  const [tabIndex, setTabIndex] = useState(defaultIndex)

  return (
    <Flex direction="column" gap={1}>
      <ButtonGroup
        isAttached
        pos={{ lg: "absolute" }}
        top={{ lg: 0 }}
        right={{ lg: 0 }}
      >
        {schema.options.map((option: any, index: number) => (
          <Button
            key={index}
            size="xs"
            variant={tabIndex === index ? "solid" : "outline"}
            colorScheme={tabIndex === index ? "brand" : undefined}
            onClick={() => {
              setTabIndex(index)

              if (isNullish(value)) return

              if (typeof value === "string") {
                onChange(null as Value)
                return
              }

              const [operator, items] = value as any[]
              // * Update the operator when switching tabs
              if (AND_OR_OPERATORS.includes(operator)) {
                onChange([AND_OR_OPERATORS[index], items] as Value)
                return
              }

              if (GROWTH_PERIODS.includes(operator)) {
                onChange([GROWTH_PERIODS[index], items] as Value)
                return
              }

              if (
                schema.options[index] instanceof ZodTuple &&
                schema.options[index].items[0] instanceof ZodDefault
              ) {
                onChange([
                  schema.options[index].items[0]._def.defaultValue(),
                  items,
                ] as Value)
                return
              }

              // * Clean numeric operators when switching tabs
              if (Object.values(NUMERIC_OPERATORS).includes(operator)) {
                onChange([index === 0 ? defaultOperator : null, items] as Value)
                return
              }

              // * Clean the value when switching tabs
              onChange(null as Value)
            }}
          >
            {getTabLabel(field, index, option)}
          </Button>
        ))}
      </ButtonGroup>
      <FieldsFromSchema
        product={product}
        schema={schema.options[tabIndex]}
        field={field}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        tab={tabIndex}
        type={type}
      />
    </Flex>
  )
}
