import { SpecterProducts } from "@prisma/client"
import { ReactSelect } from "~/components"
import { useUserLists } from "~/utils/hooks/useUserLists"

interface Props {
  product: SpecterProducts
  value: string
  onChange: (value: string) => void
}

export const ListFilterSelect = ({ product, value, onChange }: Props) => {
  const mappedProduct =
    product === SpecterProducts.fundingRounds
      ? SpecterProducts.company
      : product

  const listsQuery = useUserLists({ product: mappedProduct })

  return (
    <ReactSelect
      inputId="list-select"
      instanceId="list-select"
      value={
        value
          ? {
              value,
              label: listsQuery.data?.find((list) => list.id === value)?.name,
            }
          : null
      }
      onChange={(option) => onChange(option?.value ?? "")}
      options={
        listsQuery.data?.map((list) => ({
          value: list.id,
          label: list.name,
        })) ?? []
      }
      placeholder="Select a list..."
      isClearable
    />
  )
}
